<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-2" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="/programs">오감체험</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">상세화면</li>
        </ol>
      </nav>
      <div class="content">
        <div class="row">
          <div class="col-lg-7">
            <img v-if="this.program.imagePath" class="img-fluid rounded mt-0 mt-lg-2" :src="getTitleImage" :title="this.program.title" />
          </div>
          <div class="col-lg-5">
            <div class="ps-lg-2">
              <div class="text-end me-1 mt-3 mt-lg-0">
                <a class="ms-0" role="button" @click="toggleBookmark()" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="찜하기">
                  <i id="bookmarkIcon" :class="`fas fa-heart text-secondary fs-2`" style="color: tomato"></i>
                </a>
                <a class="ms-2" role="button" @click="share()" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="링크공유하기">
                  <span class="fas fa-share-alt text-secondary fs-2"></span>
                </a>
              </div>
              <h3 class="fw-bold mt-1">{{ program.title }}</h3>
              <div class="mb-3">
                <star-rating
                  :border-width="1"
                  border-color="#d8d8d8"
                  :rounded-corners="true"
                  :increment="1"
                  :star-size="15"
                  :read-only="true"
                  :show-rating="false"
                  :rating="this.rating"
                  :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                >
                </star-rating>
              </div>
              <div class="row justify-content-between mb-2">
                <div class="col">
                  <h4 class="fw-bold" :class="dday.color">{{ dday.label }}</h4>
                </div>
                <div class="col-auto">
                  <h4 class="fw-bold">{{ program.price ? program.price.toLocaleString('ko-KR') : 0 }}원</h4>
                </div>
              </div>
              <div class="mt-2 mb-3">
                <p class="fs-0 mb-2"><span class="fas fa-map-marker-alt me-2"></span>{{ program.area }}</p>
                <p class="fs-0 mb-2"><span class="far fa-calendar-check me-2"></span>{{ startDateLabel }}</p>
              </div>
              <!-- option1List -->
              <div class="dropdown font-sans-serif mb-3 d-grid">
                <button class="btn btn-falcon-default dropdown-toggle" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                        :class="usableProductSelect" id="productButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ option1Label }}
                </button>
                <div class="dropdown-menu dropdown-menu-start px-0" aria-labelledby="productButton">

                    <a :class="`dropdown-item ${soldOutCheck1(item) === '매진' || soldOutCheck1(item) === '[기간종료]' ? 'disabled' : '' } ${(selectProductIndex === index) ? 'active' : ''}` "
                       v-for="(item, index) in this.productList" :key="`option1-${index}`"
                        @click="selectProduct(index, item.productId)">{{ `${soldOutCheck1(item)} - ${item.value}`  }}</a>

                </div>
              </div>
              <!-- option2List -->
              <div class="dropdown font-sans-serif mb-4 d-grid" v-if="selectedOption2Toggle">
                <button class="btn btn-falcon-default dropdown-toggle" :class="usableOption2Select" id="optionButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ option2Label }}
                </button>
                <div class="dropdown-menu dropdown-menu-start px-0" aria-labelledby="optionButton" v-if="this.selectProductIndex !== 999">
                  <a :class="`dropdown-item ${soldOutCheck(item) === '매진' ? 'disabled' : ''} ${selectOption2Index === index ? 'active' : ''}`" @click="selectOption2(index, item.productId)"
                     v-for="(item, index) in productList[selectProductIndex].option2Value" :key="`option2-${index}`" >{{
                      soldOutCheck(item) + " " + item.value + `${item.extraPrice > 0 ? ` (+${item.extraPrice.toLocaleString('ko-KR')}원)` : '(+0원)'}` }}</a>
                </div>
              </div>
              <div class="row justify-content-between mb-3" v-show="1 >= 0">
                <div class="col">
                  <div class="input-group input-group-sm">
                    <button class="btn btn-sm border-300 bg-white" @click="decreaseQuantity()">-</button>
                    <input class="form-control text-center input-quantity input-spin-none bg-white" type="number" min="0" :value="quantity" style="max-width: 50px" readonly />
                    <button class="btn btn-sm border-300 bg-white" @click="increaseQuantity()">+</button>
                  </div>
                </div>
                <div class="col-auto">
                  <h5 class="fw-bold d-inline">× {{ productPrice }}원</h5>
                </div>
              </div>
              <div class="border-dashed-bottom mb-2"></div>
              <div class="row justify-content-between mb-2">
                <div class="col">
                  <p class="fw-bold">총 결제 금액</p>
                </div>
                <div class="col-auto">
                  <h3 class="fw-bold text-primary">{{ totalPayout }}원</h3>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col">
                  <button class="btn w-100 btn-primary rounded-pill" :class="quantity > 0 ? '' : 'disabled'" type="button" @click="addCart()"><span class="fas fa-cart-plus me-1"></span>장바구니</button>
                </div>
                <div class="col">
                  <button class="btn w-100 btn-primary rounded-pill" :class="quantity > 0 ? '' : 'disabled'" type="button" @click="order()"><span class="far fa-credit-card me-1"></span>구매하기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="overflow-hidden mt-4">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item"><a class="nav-link active ps-0" id="description-tab" data-bs-toggle="tab" href="#tab-description" role="tab" aria-controls="tab-description" aria-selected="true">학습설명</a></li>
                <li class="nav-item"><a class="nav-link px-2 px-md-3" id="reviews-tab" data-bs-toggle="tab" href="#tab-reviews" role="tab" aria-controls="tab-reviews" aria-selected="false" >체험후기</a></li>
                <li class="nav-item"><a class="nav-link px-2 px-md-3" id="inquiry-tab" data-bs-toggle="tab" href="#tab-inquiry" role="tab" aria-controls="tab-inquiry" aria-selected="false" >문의</a></li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="tab-description" role="tabpanel" aria-labelledby="description-tab">
                  <div class="mt-3">
                    <img v-if="this.program.detailImagePath" class="img-fluid rounded" :src="getDetailImage" style="width: 100%; height: auto" alt="제품설명이지미" />
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-reviews" role="tabpanel" aria-labelledby="reviews-tab">
                  <div class="row mt-3" v-if="reviews && reviews.length > 0">
                    <div class="col-lg-12" v-for="(review, index) in reviews" :key="`review-${index}`">
                      <ProgramReview :programReview="review" />
                      <hr class="my-3" />
                    </div>
                  </div>
                  <div class="text-center" v-else>
                    <div class="mb-4 mt-6">
                      <h4 class="text-secondary"><span class="fas fa-info-circle fs-3 me-2"></span>등록된 후기가 없습니다.</h4>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-inquiry" role="tabpanel" aria-labelledby="inquiry-tab">
                  <div class="row" v-if="inquiries && inquiries.length > 0">
                    <div class="col-lg-12" v-for="(inquiry, index) in inquiries" :key="`inquiries-${index}`">
                      <ProgramInquiry :programInquiry="inquiry" @saveInquiryTemp="saveTempData" />
                      <div class="border-bottom" v-if="index + 1 < inquiries.length"></div>
                    </div>
                  </div>
                  <div class="text-center" v-else>
                    <div class="mb-4 mt-6">
                      <h4 class="text-secondary"><span class="fas fa-info-circle fs-3 me-2"></span>등록된 문의가 없습니다.</h4>
                    </div>
                  </div>
                  <div class="border-bottom mb-3"></div>
                  <div class="d-flex flex-row-reverse mb-3 me-3">
                    <button class="btn btn-falcon-default rounded-pill btn-sm" type="button" @click="showInquiryDialog">
                      <span class="fas fa-pencil-alt me-1"></span>문의하기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgramInquiryDialog @saveInquiry="saveInquiry" />
        <ModalDialog :modalId="'modalDeleteInquiry'" :title="`문의글 삭제`" :message="`문의글을 삭제하시겠습니까?`" :params="tempData" :okButton="'삭제'" @DialogOk="deleteInquiry"/>
        <ModalDialog :modalId="'modalLogin'" :title="'로그인'" :message="`로그인이 필요한 서비스입니다. 로그인 하시겠습니까?`" :params="tempData" @DialogOk="login"/>
        <ModalDialog :modalId="'modalOpenApp'" :title="'놀토앱으로 보기'" :message="'놀토앱으로 보시겠습니까?'" :okButton="'놀토앱으로 보기'" :cancelButton="'웹으로 보기'" @DialogOk="openApp" @DialogCancel="useWeb"/>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import moment from 'moment';
import { Modal } from 'bootstrap';
import StarRating from 'vue-star-rating';
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import ProgramReview from '@/components/program/ProgramReview.vue';
import ProgramInquiry from '@/components/program/ProgramInquriy.vue';
import ProgramInquiryDialog from '@/components/program/ProgramInquiryDialog.vue';
export default {
  name: 'ProgramDetail',

  components: {
    StarRating,
    Navigation,
    FooterContent,
    ModalDialog,
    ProgramReview,
    ProgramInquiry,
    ProgramInquiryDialog,
  },
  data() {
    return {
      user: {},
      programId: -1,
      program: {},
      productList: [],
      inquiries: [],
      reviews: [],
      myFavoriteId: null,
      optionSoldOutStr: '',
      selectedOption2Toggle: true,
      selectProductIndex: 999, // product 리스트의 index
      selectOption2Index: 999,
      selectProductId: 999,
      rating: 0,

      quantity: 1,
      isBookmark: false,
      tempData: {},
      appModal: null,
      openedApp: false,
    };
  },
  created() {
    this.user = this.$func.getUser();
    this.programId = this.$route.params.id;
    this.getProgramData();
    if (this.$func.isLoggedIn()) {
      this.getBookmark();
    }
  },
  mounted() {
    this.checkAndroid();
  },
  computed: {
    getTitleImage(){
      return process.env.VUE_APP_BASE_URL + this.program.imagePath;
    },
    getDetailImage(){
      return process.env.VUE_APP_BASE_URL + this.program.detailImagePath;
    },
    startDateLabel() {
      return moment(this.program.startDate).format('YYYY년 MM월 DD일 부터');
    },
    dday() {
      let label = '알 수 없음';
      let color = 'text-600';
      switch (this.program.status) {
        case 0:
          label = '준비중';
          color = 'text-danger';
          break;
        case 1:
          {
            const startDate = moment(this.program.startDate);
            const nowDate = moment();
            const diff = parseInt(moment.duration(startDate.diff(nowDate)).asDays());
            //console.log("diff:",diff);
            if (diff > 0) {
              label = 'D-' + diff + '일';
            } else {
              label = '진행중';
            }
          }
          color = 'text-danger';
          break;
        case 2:
          label = '마감';
          color = 'text-primary';
          break;
        case 3:
          label = '종료';
          color = 'text-600';
      }
      return { label: label, color: color };
    },
    usableOption2Select(){
      return this.selectProductIndex !== 999 && this.selectedOption2Toggle ? '' : 'disabled';
    },
    usableProductSelect() {
      return this.productList && this.program.status === 1 && this.productList.length > 0 ? '' : 'disabled';
    },
    option1Label() {
      if(this.productList.length > 0){

        if(this.selectProductIndex === 999){
          try{
            return this.productList[0].label;
          }catch (e) {
            return '일정을 선택해주세요.';
          }

        }

        const productValue = this.productList[this.selectProductIndex];
        // const productValue = this.productList[0];

        //const isSoldOutStatus = `${this.optionSoldOutStr}`;
        // const isSoldOutStatus = '매진';

        return `- ${productValue.value}`;
      } else {
        return '등록된 일정 없습니다.'
      }
    },
    option2Label(){
      if(this.productList.length > 0){
        if(this.selectProductIndex === 999 || this.selectOption2Index === 999){
          try{
            return this.productList[0].option2Value[0].label;
          }catch (e) {
            return '대상을 선택해주세요.';
          }

        }

        const isSoldOutStatus = this.soldOutCheck(this.productList[this.selectProductIndex].option2Value[this.selectOption2Index]);

        return `${isSoldOutStatus} - ${this.productList[this.selectProductIndex].option2Value[this.selectOption2Index].value}`;
      } else {
        return '등록된 옵션이 없습니다.'
      }
    },
    productPrice() {

      if (this.selectProductIndex !== 999 && this.productList.length > 0) {

        const productPrice = this.program.price;
        if(this.selectOption2Index !== 999){
          return (productPrice + this.productList[this.selectProductIndex].option2Value[this.selectOption2Index].extraPrice).toLocaleString('ko-KR');
        }
        return productPrice.toLocaleString('ko-KR');
      } else {
        return 0;
      }
    },
    totalPayout() {
      if (this.selectProductIndex !== 999 && this.productList.length > 0) {

        const productPrice = this.program.price;

        if(this.selectOption2Index !== 999){
          return ((productPrice + this.productList[this.selectProductIndex].option2Value[this.selectOption2Index].extraPrice) * this.quantity).toLocaleString('ko-KR');
        }

        return (productPrice * this.quantity).toLocaleString('ko-KR');
      } else {
        return 0;
      }
    },
  },
  methods: {
    // 로그인 요청 팝업
    showLoginAlert(){
      let loginAlert = new Modal(document.getElementById('modalLogin'), {
        keyboard: false,
      });
      loginAlert.show();
    },
    getProgramData() {
      let accessToken = document.cookie.match('(^|;) ?' + 'accessToken' + '=([^;]*)(;|$)');
      accessToken = accessToken ? accessToken[2] : null;
      // console.log(accessToken);
      let url = `/experience/${this.programId}/web`;
      if(accessToken !== null){
        url = `/experience/${this.programId}`;
      }
      this.$http
          .get(url)
          .then((response) => {

            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            // console.log(response.data);
            this.program = response.data;
            this.productList = response.data.products;
            // console.log(this.productList);
            this.inquiries = response.data.inquiries;
            this.reviews = response.data.reviews;
            if(this.reviews.length > 0){
              this.reviews.forEach((v) => {
                this.rating = this.rating + v.rating;
              })
              if(this.rating > 0){

                this.rating = parseInt((this.rating / this.reviews.length).toFixed(1));
              }
            }
            // 옵션 2가 없다면 옵션 1만 실행하기 위해서 사용되는 조건문

            let option2CheckCount = 0;
            for(let i = 0; i < this.productList.length; i++){
              if(this.productList[i].option2Value == null){
                option2CheckCount++;
              }
            }

            if(option2CheckCount === this.productList.length){
              this.selectedOption2Toggle = false;
            }


            this.productList = this.transformProducts(this.productList);

            this.$forceUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    transformProducts(products) {
      const transformed = {};

      products.forEach(product => {
        const option1Id = product.option1Value.id;

        let option1Value = {
          ...product.option1Value,
          productId: product.id,
          extraPrice: product.extraPrice,
          isUse: product.isUse,
          del: product.del,
          endDate: product.endDate,
          isSoldOut: product.isSoldOut,
          quantity: product.quantity,
          reservedQuantity: product.reservedQuantity
        };

        let option2Value = product.option2Value;

        if (!transformed[option1Id]) {
          transformed[option1Id] = {
            ...option1Value,
            option2Value: []
          };
        }

        if (option2Value) {
          option2Value = {...option2Value,
            productId: product.id,
            extraPrice: product.extraPrice,
            isSoldOut: product.isSoldOut,
            quantity: product.quantity,
            reservedQuantity: product.reservedQuantity
          }
          transformed[option1Id].option2Value.push(option2Value);
        }
      });


      return Object.values(transformed);
    },
    soldOutCheck1(product){
      try{
        if(product.option2Value.length > 1){
          let soldOutCheck = [];
          for(let i = 0; i < product.option2Value.length; i++){
            soldOutCheck.push(product.option2Value[i].quantity === product.option2Value[i].reservedQuantity);
          }

          const endData = new Date(product.endDate);
          const nowData = new Date();

          if(nowData > endData){
            return '[기간종료]'
          }
          return product.isSoldOut || (!soldOutCheck.includes(false) ) ? '매진' : '';

        } else {

          const endData = new Date(product.endDate);
          const nowData = new Date();
          if(nowData > endData){
            return '[기간종료]'
          }

          return product.isSoldOut || (product.quantity === product.reservedQuantity ) ? '매진' : '';
        }
      }catch (e) {
        console.log(e)
      }

    },
    // 매진 여부
    soldOutCheck(product){
      return product.isSoldOut || (product.quantity === product.reservedQuantity ) ? '매진' : '';
    },
    // 옵션 2선택
    selectOption2(index, productId){
      this.selectOption2Index = index;
      this.selectProductId = productId;
      this.quantity = 1;
    },

    // 옵션 선택
    selectProduct(index, productId) {
      this.quantity = 1;
      // 옵션 1 재선택마다 옵션 2 초기화
      this.selectOption2Index = 999;
      this.selectProductIndex = index;
      this.selectProductId = productId;
    },

    //todo api 연결 필요 체험 후기
    saveTempData(data) {
      this.tempData = data;
    },
    // 찜 여부
    getBookmark() {
      // console.log('getBookmark', this.$http.defaults.headers);
      this.$http
        .get('/favorite', {
          params: {
            itemType: 3,
            skip: 0,
            limit: 10000,
          },
        })
        .then((response) => {
          //console.log('getBookmark-response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }

          // console.log('내 찜 리스트', response.data);
          // 찜 여부 확인
          for(let i = 0; i < response.data.length; i++){
            if(`${response.data[i].item.id}` === `${this.programId}`){
              // console.log('찜 true');
              this.myFavoriteId = response.data[i].id;
              this.isBookmark = true;
              this.updateBookmarkIcon(true);
              break;
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
    },

    increaseQuantity() {
      if(this.selectProductIndex === 999){
        this.$toast.warning('일정을 선택해주세요.');
        return;
      }
      const product = this.productList[this.selectProductIndex];

      if(product.option2Value.length > 0 && this.selectOption2Index === 999){
        this.$toast.warning('인원을 선택해주세요.');
        return;
      }


      // 옵션 1만 잇는 상품의 경우
      if(this.selectOption2Index === 999 && product.option2Value.length === 0){
        const availableQuantity = product.quantity - product.reservedQuantity;

        if (availableQuantity <= this.quantity) {
          this.$toast.warning('최대 구매 가능한 수량은 ' + availableQuantity + '개 입니다.');
        } else {
          const getQuantity = this.quantity + 1;

          if(product.quantity < product.reservedQuantity + getQuantity){
            this.$toast.warning('최대 구매 가능한 수량은 ' + availableQuantity + '개 입니다.');
            return;
          }
          this.quantity += 1;
        }
      }

      if(this.selectOption2Index !== 999 && product.option2Value.length > 0){

        const productOption2Value = product.option2Value[this.selectOption2Index];
        const availableQuantity = productOption2Value.quantity - productOption2Value.reservedQuantity;

        if (availableQuantity <= this.quantity) {
          this.$toast.warning('최대 구매 가능한 수량은 ' + availableQuantity + '개 입니다.');
        } else {
          const getQuantity = this.quantity + 1;

          if(productOption2Value.quantity < productOption2Value.reservedQuantity + getQuantity){
            this.$toast.warning('최대 구매 가능한 수량은 ' + availableQuantity + '개 입니다.');
            return;
          }
          this.quantity += 1;
        }
      }

    },
    decreaseQuantity() {
      if (this.quantity === 1) {
        this.$toast.warning('1개 이상부터 구매하실 수 있습니다.');
      } else {
        this.quantity -= 1;
      }
    },

    showInquiryDialog() {
      if (this.$func.isLoggedIn()) {
        const inquiryModal = new Modal(document.getElementById('modalProgramInquiry'));
        inquiryModal.show();
      } else {

        this.tempData = { name: 'program', params: { id: this.programId } };
        this.showLoginAlert();
      }
    },

    saveInquiry(inquiry) {
      this.$http
        .post(`/experience/${this.programId}/inquiry`, {
          "inquiry": inquiry.question,
          "isPrivacy": inquiry.isPrivacy
        })
        .then((response) => {
          console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }

          if(response.status === 201 || response.status === 200){
            this.$toast.success('문의가 작성 되었습니다.');
            this.inquiries.push(response.data);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    },

    //todo api 연결
    deleteInquiry(programInquiry) {
      console.log('deleteInquiry-programInquiry:', programInquiry);

      if (programInquiry) {
        this.$http
          .delete(`/experience/${this.programId}/inquiry/${programInquiry.id}`)
          .then((response) => {
            console.log(response);
            this.$toast.success('문의가 삭제되었습니다.');
            // inquiries에 있는 id와 programInquiry에 잇는 id를 비교하여 동일한 id가 있으면 삭제한다.
            this.inquiries = this.inquiries.filter(inquiry => inquiry.id !== programInquiry.id);

          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    addCart() {
      if (this.$func.isLoggedIn()) {
        // this.$router.push({
        //   name: 'add-cart',
        //   params: { programId: this.programId, programProductId: this.productList[this.selectProductIndex].id, quantity: this.quantity },
        // })

        if(this.selectProductIndex === 999){
          this.$toast.warning('일정을 선택해주세요.');
          return;
        }

        if(this.selectedOption2Toggle && this.selectOption2Index === 999){
          this.$toast.warning('대상을 선택해주세요.');
          return;
        }

        this.$http
            .post('/cart', {
              programId: parseInt(this.programId),
              programProductId: this.selectProductId,
              quantity: this.quantity

            })
            .then((response) => {
              //console.log(response);
              if (response.data.error) {
                this.$toast.error(response.data.message);
                return;
              }
              if(response.status === 201 || response.status === 200){
                this.$toast.success('장바구니에 추가되었습니다.');
              }

              if(response.status === 409){
                this.$toast.success('장바구니에 중복 추가 불가능 합니다.');
              }

            })
            .catch((error) => {
              if(error.statusCode === 409){
                this.$toast.success('장바구니에 중복 추가 불가능 합니다.');
              } else {
                this.$toast.success('서버와의 연결이 불안정 합니다.');
              }
              console.log(error);
            });
      } else {
        if(this.selectProductIndex === 999){
          this.showLoginAlert();
          return;
        }

        this.tempData = { name: 'add-cart', params: { programId: this.programId, programProductId: this.selectProductId, quantity: this.quantity } };
        this.showLoginAlert();
      }
    },

    order() {
      if (this.$func.isLoggedIn()) {
        // option2가 있는 상태에서 옵션2를 선택하지 않고 구매하기 버튼을 누른 경우
        if(this.selectedOption2Toggle && this.selectOption2Index === 999){
          this.$toast.warning('대상을 선택해주세요.');
          return;
        }

        if(this.selectProductIndex === 999){
          this.$toast.warning('일정을 선택해주세요.');
          return;
        }

        this.$router.push({
          name: 'order',
          params: {
            programId: this.programId,
            programProductId: this.selectProductId,
            quantity: this.quantity,
          },
        });
      } else {
        if(this.selectProductIndex === 999){
          this.showLoginAlert();
          return;
        }

        this.tempData = { name: 'order', params: { programId: this.programId, programProductId: this.selectProductId, quantity: this.quantity } };
        this.showLoginAlert();
      }
    },

    login(routeParams) {
      this.$router.push({
        name: 'login',
        params: routeParams,
      });
    },

    share() {
      if (navigator.share) {
        //can use 'navigator.share' over https only.
        navigator.share({
          title: this.program.TITLE,
          text: '내 손안의 역사가이드 놀토',
          url: `${process.env.VUE_APP_WEB_URL}/program/${this.program.id}`,
        });
      } else {
        this.$copyText(`${process.env.VUE_APP_WEB_URL}/program/${this.program.id}`);
        this.$toast.info('오감체험 링크를 복사하였습니다. 원하시는 곳에 붙여넣으세요.');
      }
    },

    toggleBookmark() {
      if (this.$func.isLoggedIn()) {
        if(this.isBookmark){

          this.$http
              .delete(`/favorite/${this.myFavoriteId}`)
              .then((response) => {
                //console.log(response);
                if (response.data.error) {
                  this.$toast.error(response.data.message);
                  return;
                }
                this.isBookmark = !this.isBookmark;
                this.updateBookmarkIcon(this.isBookmark);
                this.$toast.info('찜목록에서 삭제되었습니다.');
              })
              .catch((error) => {
                console.log(error);
              });

        } else {
          this.$http
              .post('/favorite',{
                itemType: 3,
                itemId: parseInt(this.programId),
              })
              .then((response) => {
                //console.log(response);
                if (response.data.error) {
                  this.$toast.error(response.data.message);
                  return;
                }
                this.myFavoriteId = response.data.id;
                this.isBookmark = !this.isBookmark;

                this.updateBookmarkIcon(this.isBookmark);
                this.$toast.info('찜목록에 추가되었습니다.');
              })
              .catch((error) => {
                console.log(error);
              });

        }

      } else {
        let routeParams = { name: 'program', params: { id: this.programId } };
        console.log('routeParams', routeParams);
        this.tempData = routeParams;
        const loginAlert = new Modal(document.getElementById('modalLogin'));
        loginAlert.show();
      }
    },
    updateBookmarkIcon(toggle) {
      if (toggle) {

        document.getElementById('bookmarkIcon').classList.replace('text-secondary','text-danger');
      } else {
        document.getElementById('bookmarkIcon').classList.replace('text-danger', 'text-secondary');
      }
    },
    checkAndroid() {
      const userAgent = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
      //console.log(userAgent);
      if (userAgent.indexOf('android') > -1) {
        const useWeb = this.$func.getCookie('useWeb');
        if (useWeb == null || useWeb == 'false') {
          this.appModal = new Modal(document.getElementById('modalOpenApp'));
          this.appModal.show();
        }
      }
    },

    openApp() {
      const targetUrl = 'newnolto://com.mynolto.android?program&id=' + this.$route.params.id;
      setTimeout(function () {
        location.href = targetUrl;
      }, 100);

      setTimeout(function () {
        if (document.webkitHidden || document.hidden) {
          this.openedApp = true;
        }
        if (!this.openedApp) {
          location.href = 'https://play.google.com/store/apps/details?id=com.mynolto.android';
        }
      }, 1500);

      if (this.appModal) {
        this.appModal.hide();
      }
    },

    useWeb() {
      this.$func.setCookie('useWeb', true, 1);
      if (this.appModal) {
        this.appModal.hide();
      }
    },
  },
};
</script>
