<template>
  <div v-if="reservation.id !== undefined" class="d-flex border-bottom p-1 align-items-center" style="position: relative">
<!--    <a class="avatar avatar-4xl" :href="`/reservation/${reservation.id}`">-->
    <span :class=" `${(reservation.status === 3 || reservation.status === 4)  ? 'd-none' : ''}`"
          style="position: absolute; bottom:20px; right: 0; display: flex; height: 35px; align-items: center; justify-content: center; border-radius: 50px; width: 100px; background: gainsboro; color: white" type="button" @click="cancelBtn()">
                취소하기
              </span>
    <a class="avatar avatar-4xl" >
      <img class="rounded-soft" :src="imageUrl" :alt="reservation.programTitle" />
    </a>
    <div class="flex-1 ms-3">
      <div class="row align-items-between">
        <div class="col-lg-10">
          <div class="d-flex flex-column">
            <div style="width: 100%; display: flex; gap: 10px;  margin-bottom: 10px ">
              <span :class="`${$func.getReservationStatusColor(parseInt(reservation.status))}`"
                    style="display: flex; height: 35px; align-items: center; justify-content: center; border-radius: 50px; width: 100px;">
                {{ $func.getReservationStatusLabel(parseInt(reservation.status)) }}
              </span>
            </div>

<!--            <h5 class="mb-2"><a :href="`/reservation/${reservation.RESERVATION_NO}`">{{ reservation.title }}</a></h5>-->
            <h5 class="mb-2" style="font-weight: bold">{{ reservation.programTitle }}</h5>

            <div class="d-flex align-items-center align-self-start gap-2">
              <div class="fs--3 mb-1" style="font-weight: bold">일시</div>
              <div class="fs--1 mb-1">{{ reservation.programTime }}</div>
            </div>

            <div class="d-flex align-items-center align-self-start gap-2">
              <div class="fs--3 mb-1" style="font-weight: bold">인원</div>
              <div class="fs--1 mb-1">{{ reservation.quantity + "명" }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="flex-1 ms-3">
      <div class="row align-items-between">
        <div class="col-lg-8">
          <div class="d-flex flex-column">


            <div class="d-flex align-items-center align-self-start gap-2">
              <div class="fs--3 mb-1" style="font-weight: bold">예약일</div>
              <div class="fs--1 mb-1">{{ reservationTime }}</div>
            </div>
            <div class="d-flex align-items-center align-self-start gap-2">
              <div class="fs--3 mb-1" style="font-weight: bold">결제금액</div>
              <div v-if="reservation.payout !== undefined" class="fs--1 mb-1">{{reservation.payout.toLocaleString('ko-KR') }}원</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'Cart',
  components: {},
  data() {
    return {};
  },
  props: {
    reservation: {
      id: 0,
      programTitle: '오감체험 제목',
      programId: 0,
      programTime: '10월 26일(목) 18:00 ~ 19:00',
      imagePath: '/v2/resources/images/a9f1989e-9111-47ea-9f62-1a70b18e767e.jpg',
      status: 0,
      quantity: 1,
      payout: 0,
      reservationTime: '2024-06-11T09:58:37.522Z',
    },
  },
  created() {
    console.debug('created - reservation:', this.reservation);
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BASE_URL + this.reservation.imagePath;
    },
    reservationTime() {
      return moment(this.reservation.reservationTime.toLocaleString()).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {
    cancelBtn(){
      this.$emit('CancelEmit', this.reservation);
    }
  }
};
</script>
