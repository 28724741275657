import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/components/Home.vue";
import Welcome from "@/components/Welcome.vue";
import NotFound from "@/components/404.vue";
import Login from "@/components/auth/Login.vue";
import AuthCallback from "@/components/auth/AuthCallback.vue";
import LogoutCallback from "@/components/auth/LogoutCallback.vue";
import ProgramList from "@/components/program/ProgramList.vue";
import BookmarkList from "@/components/program/BookmarkList.vue";
import ProgramDetail from "@/components/program/ProgramDetail.vue";
import AddCart from "@/components/cart/AddCart.vue";
import CartList from "@/components/cart/CartList.vue";
import Order from "@/components/order/Order.vue";
import ReservationList from "@/components/order/ReservationList.vue";
import ReservationDetail from "@/components/order/ReservationDetail.vue";
import Logout from "@/components/auth/Logout.vue";
import profileEdit from "@/components/auth/profileEdit.vue";
import AdminHome from "@/components/admin/Home.vue";
import AdminProgramList from "@/components/admin/program/ProgramList.vue";
import AdminProgramOption from "@/components/admin/program/ProgramOption.vue";
import AdminProgramInterest from "@/components/admin/program/ProgramInterest.vue";
import AdminProgramReservation from "@/components/admin/program/ProgramReservation.vue";
import AdminProgramReport from "@/components/admin/program/ProgramReport.vue";
import AdminProgramReview from "@/components/admin/program/ProgramReview.vue";
import AdminProgramInquiry from "@/components/admin/program/ProgramInquiry.vue";
import AdminUserList from "@/components/admin/user/UserList.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',component: Home,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/auth-callback/:sns',
    name: 'auth-callback',
    component: AuthCallback,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/logout-callback',
    name: 'logout-callback',
    component: LogoutCallback,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/programs',
    name: 'user-programs',
    component: ProgramList,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    component: BookmarkList,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/program/:id',
    name: 'program',
    component: ProgramDetail,
    meta: { unauthorized: true,  adminOnly: false },
  },
  {
    path: '/add-cart',
    name: 'add-cart',
    component: AddCart,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartList,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: ReservationList,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/reservation/:reservationNo',
    name: 'reservation',
    component: ReservationDetail,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/profile/:type',
    name: 'profile',
    component: profileEdit,
    meta: { unauthorized: false,  adminOnly: false },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminHome,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/programs',
    name: 'admin-programs',
    component: AdminProgramList,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-option/:id',
    name: 'admin-program-option',
    component: AdminProgramOption,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-interest/:id',
    name: 'admin-program-interest',
    component: AdminProgramInterest,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-reservation/:id',
    name: 'admin-program-reservation',
    component: AdminProgramReservation,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-report/:id',
    name: 'admin-program-report',
    component: AdminProgramReport,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-review/:id',
    name: 'admin-program-review',
    component: AdminProgramReview,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/program-inquiry/:id',
    name: 'admin-program-inquiry',
    component: AdminProgramInquiry,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '/admin/user-list',
    name: 'admin-user-list',
    component: AdminUserList,
    meta: { unauthorized: false,  adminOnly: true },
  },
  {
    path: '*',
    redirect: '/404',
    // 아래처럼 바로 NotFound 경로를 매칭해도 됨
    // component: NotFound
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// router.beforeEach((to, from, next) => {
//
//
//   if (to.matched.some((record) => record.meta.unauthorized)) {
//     return next();
//   } else {
//     const user = Vue.prototype.$func.getUser();
//     if (user) {
//       if (to.matched.some((record) => record.meta.adminOnly)) {
//         if (user.role == 'ROLE_ADMIN' || user.role == 'ROLE_MANAGER') {
//           return next();
//         } else {
//           console.log("user is not admin or manager");
//           return next({
//             path: '/404',
//           });
//         }
//       }else {
//         return next();
//       }
//     } else {
//       return next();
//       // return next({
//       //   path: '/login',
//       //   query: {
//       //     nextUrl: to.fullPath,
//       //   },
//       // });
//     }
//   }
// });

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

export default router;
